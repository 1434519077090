<template>
  <div
    class="dashboard-wrap"
    style="background-image: url('images/attractor-bg.png')"
  >
    <div class="container">
      <div class="user-settings">
        <div class="user-settings-top">
          <div
            class="user-image"
            style="
              background-image: url(images/user_avatar.png);
            "
          ></div>
          <div class="user-titles">
            {{ fname }} {{ lname }} <span>{{email}}</span>
          </div>
        </div>
        <div class="tab-section">
          <ul class="nav nav-tabs">
            <li class="active">
              <a data-toggle="tab" href="#personalDetails" aria-expanded="true"
                >Personal Details</a
              >
            </li>
            <li>
              <a data-toggle="tab" href="#AccountDetials">Account Details</a>
            </li>
          </ul>

          <div class="tab-content">
            <div id="personalDetails" class="tab-pane in active">
              <h3>Change Personal Infomation</h3>
              <form @submit.prevent>
                <div class="row">
                  <div class="form-group col-6">
                    <label>First Name</label>
                    <input type="test" name="" class="form-control" v-model="form.fname" v-on:input="changeListner" />
                  </div>
                  <div class="form-group col-6">
                    <label>Last Name</label>
                    <input type="test" name="" class="form-control" v-model="form.lname" v-on:input="changeListner" />
                  </div>
                  <div class="form-group col-6">
                    <label>Email</label>
                    <input type="test" name="" class="form-control" v-model="form.email" v-on:input="changeListner" />
                  </div>
                  <div class="form-group col-6">
                    <label>Gender</label>
                    <select class="form-control" v-model="form.gender" @change="changeListner">
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                  <!-- <div class="form-group col-12">
                    <label>Change Profile Picture</label>
                    <input type="file" name="" class="form-control" />
                  </div> -->
                  <div class="form-group col-12">
                    <input v-if="updateEnabled" type="submit" name="" value="Update" class="btn" @click="updateDetails" />
                  </div>
                </div>
              </form>
            </div>
            <div id="AccountDetials" class="tab-pane fade">
              <h3>Change Password</h3>
              <form @submit.prevent="updatePassword">
                <div class="row">
                  <div class="form-group col-6">
                    <label>Current Password</label>
                    <input type="password" name="" class="form-control" v-model="password.currentPassword" required />
                  </div>
                  <div class="form-group col-6"></div>
                  <div class="form-group col-6">
                    <label>New Password</label>
                    <input type="password" name="" class="form-control" v-model="password.newPassword" required/>
                  </div>
                  <div class="form-group col-6">
                    <label>Comfirm New Password</label>
                    <input type="password" name="" class="form-control" v-model="password.confirmNewPassword" required/>
                  </div>
                  <div class="form-group col-12">
                    <input type="submit" name="" value="Update" class="btn" />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="loader-overlay" v-if="loading == true">
      <Loader> </Loader>
    </div>
  </div>
</template>

<script>
import DataService from "@/api/DataService.js";
import store from "@/store/index.js";
import Loader from "@/components/loader.vue";
export default {
  data() {
    return {
        hashedUsername: "",
      fname: "",
      lname: "",
      email: "",
      gender: "",
      form: {
        fname: "",
        lname: "",
        email: "",
        gender: "",
      },
      password: {
          currentPassword: "",
          newPassword: "",
          confirmNewPassword: ""
      },
      updateEnabled: false,
      loading: false,
    };
  },
  components:{
      Loader
  },
  mounted() {
      let hashed_username = localStorage.getItem("hashed_username");
        this.hashedUsername = hashed_username;
    if (store.state.userDetails.dataFetched == true) {
      this.fname = this.form.fname = store.state.userDetails.fname;
      this.lname = this.form.lname = store.state.userDetails.lname;
      this.email = this.form.email = store.state.userDetails.email;
      this.gender = this.form.gender = store.state.userDetails.gender;
    } else {
      this.fetchData();
    }
  },

  methods: {
    async fetchData() {
        this.loading = true;
      
      await DataService.fetchUserData(this.hashedUsername).then((response) => {
        this.fname = this.form.fname = response.data.fname;
        this.lname = this.form.lname = response.data.lname;
        this.email = this.form.email = response.data.email;
        this.gender = this.form.gender = response.data.gender;

        store.state.userDetails.fname = response.data.fname;
        store.state.userDetails.lname = response.data.lname;
        store.state.userDetails.email = response.data.email;
        store.state.userDetails.gender = response.data.gender;
        store.state.userDetails.dataFetched == true;
      });
      this.loading = false;
    },
    async updateDetails() {
        this.loading = true;
        
        await DataService.updateUserData(this.form.fname,this.form.lname,this.form.email,this.form.gender,this.hashedUsername).then((response)=>{
            if(response.data.res_code == "update_success"){
               this.fetchData();
            }
        });
        this.loading = false;
    },
    changeListner(){
        
        if( (this.fname !== this.form.fname) || (this.lname !== this.form.lname) || (this.email !== this.form.email) || (this.gender !== this.form.gender) ) {
            this.updateEnabled = true;
        }else{
            this.updateEnabled = false;
        }
    },
    async updatePassword(){
        this.loading = true;
  
        if(this.password.newPassword === this.password.confirmNewPassword){

            await DataService.updatePassword(this.password.currentPassword,this.password.newPassword,this.hashedUsername).then((response)=>{
                console.log(response);
                if(response.data.res_code == "update_success"){
                    this.password.currentPassword = "";
                    this.password.newPassword = "";
                    this.password.confirmNewPassword = "";
                }

            });
        
        }
        this.loading = false;
    }
  },
};
</script>

<style>
</style>